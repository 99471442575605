import { CSSProperties, FC } from 'react';

import { hasRichTextContent } from '@lib/slice-machine/richText';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, Grid } from '@mui/material';
import { DynamicImage } from '@ui/gallery/DynamicImage';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';

import { DefaultSliceItem } from '../types';
import { DEFAULT_SPACING_BETWEEN_ELEMENTS } from '../../constants';

type Props = DefaultSliceItem;

export const FullWidthSlide: FC<Props> = ({
  background,
  backgroundColor,
  ctaBackgroundColor,
  title,
  description,
  imageFit,
  ctaTextColor,
  ctaTextLink,
  textAlign = 'left',
}) => {
  const styles = getStyles(textAlign);
  return (
    <Box sx={styles.getValue('root')} bgcolor={backgroundColor ?? undefined}>
      <Box sx={styles.getValue('imageContainer')}>
        {background?.url && (
          <DynamicImage
            src={background.url}
            alt={background.alt ?? ''}
            objectFit={imageFit ?? undefined}
            fill
            containerProps={{ sx: styles.getValue('image') }}
          />
        )}
      </Box>
      <Box sx={styles.getValue('contentWrapper')}>
        <Box sx={styles.getValue('content')}>
          <Grid
            container
            flexDirection="column"
            flexWrap="nowrap"
            width="100%"
            height="100%"
          >
            {hasRichTextContent(title) && (
              <Grid item xs={12}>
                <CustomRichText render={title} />
              </Grid>
            )}
            {hasRichTextContent(description) && (
              <Grid item>
                <CustomRichText
                  render={description}
                  containerSx={styles.getValue('description')}
                />
              </Grid>
            )}
            {hasRichTextContent(ctaTextLink) && (
              <Grid item mt={DEFAULT_SPACING_BETWEEN_ELEMENTS}>
                <CustomRichText
                  render={ctaTextLink}
                  linkBtnProps={{
                    linkAsButton: true,
                    color: ctaBackgroundColor ?? undefined,
                    textProps: {
                      color: ctaTextColor ?? undefined,
                      fontSize: 18,
                    },
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

const getStyles = (textAlign: CSSProperties['textAlign']) =>
  new SxStyles({
    root: {
      width: '100%',
      height: '100%',
    },
    imageContainer: {
      width: '100%',
      minHeight: 550,
      height: '100%',
      position: 'relative',
    },
    image: {
      pointerEvents: 'none',
      width: '100%',
      height: '100%',
    },
    contentWrapper: {
      position: 'absolute',
      width: '100%',
      top: '50%',
      right: '50%',
      transform: 'translate(50%,-50%)',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    content: {
      width: '100vw',
      textAlign,
      fontSize: 25,
      height: '100%',
      padding: (theme) => theme.spacing(9),
      overflow: 'hidden',
    },
    description: {
      '& p': {
        lineHeight: 1.1,
      },
    },
  });
