import { CSSProperties, FC } from 'react';

import { hasRichTextContent } from '@lib/slice-machine/richText';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, Grid } from '@mui/material';
import { ContentSpacer } from '@templates/common/layout/ContentSpacer';
import { DynamicImage } from '@ui/gallery/DynamicImage';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';

import { DefaultSliceItem, SlideDirection } from '../types';

type Props = DefaultSliceItem;

export const HalfHalfSlide: FC<Props> = ({
  background,
  ctaBackgroundColor,
  backgroundColor,
  title,
  description,
  imageFit,
  ctaTextColor,
  ctaTextLink,
  slideDirection = 'row',
  imageRotation,
  textAlign = 'left',
}) => {
  const styles = getStyles(slideDirection, textAlign, imageRotation);
  return (
    <Box sx={styles.getValue('root')} bgcolor={backgroundColor ?? undefined}>
      <ContentSpacer
        isPadded={false}
        contentProps={{
          sx: styles.getValue('wrapper'),
        }}
      >
        <Box sx={styles.getValue('content')}>
          <Grid container flexDirection="column" spacing={1} flexWrap="nowrap">
            {hasRichTextContent(title) && (
              <Grid item>
                <CustomRichText render={title} />
              </Grid>
            )}
            {hasRichTextContent(description) && (
              <Grid item>
                <CustomRichText render={description} />
              </Grid>
            )}
            {hasRichTextContent(ctaTextLink) && (
              <Grid item mt={2}>
                <CustomRichText
                  render={ctaTextLink}
                  linkBtnProps={{
                    linkAsButton: true,
                    color: ctaBackgroundColor ?? undefined,
                    textProps: {
                      color: ctaTextColor ?? undefined,
                      fontSize: 18,
                    },
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Box>
        {background.url && (
          <Box sx={styles.getValue('imageContainer')}>
            {background?.url && (
              <DynamicImage
                src={background.url}
                alt={background.alt ?? ''}
                fill
                height={550}
                objectFit={imageFit ?? undefined}
                containerProps={{ sx: styles.getValue('image') }}
              />
            )}
          </Box>
        )}
      </ContentSpacer>
    </Box>
  );
};

const getStyles = (
  slideDirection: SlideDirection,
  textAlign: CSSProperties['textAlign'],
  imageRotation?: number,
) => {
  return new SxStyles({
    root: {
      display: 'flex',
      width: '100%',
      minHeight: 550,
    },
    wrapper: {
      display: 'flex',
      flexDirection: slideDirection,
      width: '100%',
      height: '100%',
    },
    imageContainer: {
      width:
        slideDirection === 'column' || slideDirection === 'column-reverse'
          ? 'auto'
          : '50%',
      height: 'inherit',
      display: {
        xs: 'none',
        md: 'flex',
      },
    },
    image: {
      pointerEvents: 'none',
      ...(imageRotation && { transform: `rotate(${imageRotation}deg)` }),
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign,
      width: {
        xs: '100%',
      },
      fontSize: 25,
      height: '100%',
      padding: 1,
      pb: 0,
      overflow: 'hidden',
      '& * p': {
        margin: 0,
      },
    },
  });
};
